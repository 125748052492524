import React, {useEffect, useState} from 'react';
import {
  FaCheck,
  FaMapMarkedAlt,
  FaMapPin,
  FaRegTrashAlt,
} from 'react-icons/fa';
import { MdMail, MdOutlinePhoneAndroid } from 'react-icons/md';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../hooks/common/redux.hooks';
import {getAllSellers, getPotentialPartners} from '../../../../redux/actions/sellers';
import MKCard from '../../../UI/MKCard';
import MKButton from '../../../UI/MKButton';
import PotentialPartnerDetails from './Details';
import { LuBadgeInfo } from 'react-icons/lu';
import {
  approvePotentialPartnerService,
  rejectPotentialPartnerService,
} from '../../../../services/admin/partners';
import { toast } from 'react-toastify';

const PotentialPartners = () => {
  const [activePartnerDetail, setActivePartnerDetail] = useState(null);
  const { isPotentialPartnerLoading, potentialPartners } = useAppSelector(
    (state) => state.seller
  );
  const dispatch = useAppDispatch();


  useEffect(() => {
    if (!potentialPartners) {
      dispatch(getPotentialPartners());
    }
  }, []);

  const handleShowPartnerDetail = (partnerDetail) => {
    setActivePartnerDetail(partnerDetail);
  };
  const handleClosePartnerDetail = () => {
    setActivePartnerDetail(null);
  };
  const handleApprovePartner = async (
    e: React.MouseEvent<HTMLButtonElement>,
    partner
  ) => {
    e.stopPropagation();
    const approvePartnerResponse = await approvePotentialPartnerService(
      partner
    );
    if (
      approvePartnerResponse &&
      approvePartnerResponse.data?.success === true
    ) {
      dispatch(getPotentialPartners());
      toast.success(`${approvePartnerResponse.data?.message}`);
    }
  };
  const handleRejectPartner = async (
    e: React.MouseEvent<HTMLButtonElement>,
    partner
  ) => {
    e.stopPropagation();

    const rejectPartner = await rejectPotentialPartnerService(partner);
    if (rejectPartner && rejectPartner.data?.success === true) {
      dispatch(getPotentialPartners());
      toast.success(`${rejectPartner.data?.message}`);
    }
  };

  return (
    <>
      <section className='my-1 rounded-sm border border-dashed border-orange-400 bg-orange-50 p-2 md:my-2 md:p-4'>
        <div className='flex flex-row items-center gap-2'>
          <LuBadgeInfo className=' h-8 w-8 self-center text-orange-300' />
          <section>
            <p className='text-lg font-bold'>Afiliados en etapa de revisión</p>
            <p className='text-sm text-slate-500'>
              Los Afiliado en etapa de revisión aún no tienen acceso a la
              plataforma Merkoline. Puedes realizar las siguientes acciones para
              cada cuenta
            </p>
            <ul className='ml-6 list-disc text-sm text-slate-500'>
              <li>
                Aprobar afiliado: Al aprobar una afiliado le permitirles iniciar
                sesión y que administrar su cuenta.
              </li>
              <li>
                Rechazar afiliado: Al rechazar un afiliado se eliminará de esta
                lista y el acceso permanecerá denegado.
              </li>
            </ul>
          </section>
        </div>
      </section>
      <section className='flex flex-col gap-2'>
        {Array.isArray(potentialPartners) &&
          potentialPartners?.map((potentialPartner) => (
            <MKButton
              variant='none'
              key={`seller-partners-${potentialPartner._id}`}
              className='w-full'
              onClick={() => handleShowPartnerDetail(potentialPartner)}
            >
              <MKCard className='flex flex-col gap-4 text-left'>
                <section className='border-b pb-2'>
                  <section className='flex items-center justify-between '>
                    <p>{potentialPartner.name}</p>

                    <div>
                      <section className='flex gap-2'>
                        <MKButton
                          variant='outlined'
                          className='flex items-center px-2 py-1'
                          onClick={(e) =>
                            handleApprovePartner(e, potentialPartner)
                          }
                        >
                          <FaCheck />
                          <p>Aprobar</p>
                        </MKButton>
                        <MKButton
                          variant='outlined'
                          className='flex items-center border-red-400 px-2 py-1 hover:bg-red-50'
                          onClick={(e) =>
                            handleRejectPartner(e, potentialPartner)
                          }
                        >
                          <FaRegTrashAlt className='text-red-800' />
                          <p className='text-red-800'>Rechazar </p>
                        </MKButton>
                      </section>
                    </div>
                  </section>
                </section>

                <div className='flex flex-wrap gap-2 md:gap-8'>
                  {/* general info */}
                  <section className='flex w-full flex-col gap-2 sm:w-auto  md:flex-row md:gap-8'>
                    <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <MdMail className='text-primary-500' />
                      </div>
                      <div>
                        <p className='text-left font-semibold'>Correo</p>
                        <p className='text-gray-700'>
                          {potentialPartner.email}
                        </p>
                      </div>
                    </section>
                    <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <MdOutlinePhoneAndroid className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>Teléfono</p>
                        <p className='text-gray-700'>
                          {potentialPartner.phoneNumber}
                        </p>
                      </div>
                    </section>
                  </section>

                  {/* location info */}
                  <section className='flex w-full  flex-col gap-2 sm:w-auto md:flex-row md:gap-8'>
                    <div className='flex items-center gap-2  rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <FaMapMarkedAlt className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>
                          {potentialPartner?.address?.region}
                        </p>
                        <p className='text-gray-700'>
                          {potentialPartner?.address?.city}
                        </p>
                      </div>
                    </div>
                    <div className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <FaMapPin className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>Barrio / sector</p>
                        <p className='text-gray-700'>
                          {potentialPartner?.address?.neighborhood}
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </MKCard>
            </MKButton>
          ))}
      </section>
      {activePartnerDetail && (
        <PotentialPartnerDetails
          partner={activePartnerDetail}
          handleClosePartnerDetail={handleClosePartnerDetail}
        />
      )}
    </>
  );
};

export default PotentialPartners;

import { useDriverSessionStore } from '../../store/driversSession';
// import DriverAvailability from './DriverAccount/Availability';
// import OrdersTab from './DriverOrders/OrdersTab';

const DriversDashboard = () => {
  const { driverSession } = useDriverSessionStore();

  return (
    <section className='w-full px-2 md:px-8'>
      <div className=' w-full pt-5'>
        <p className='text-lg font-bold'>Bienvenido driver</p>
        <p className='text-gray-500'>
          Aqui puedes ver detalles de tu cuenta y que están disponibles para
          nuestros afiliados te contacten
        </p>
      </div>
      <section className='my-4 flex items-center gap-2'>
        <img
          src={driverSession?.profileImage}
          alt=''
          className='h-[50px] w-[50px] rounded-full object-cover'
        />
        <p className='text-lg font-bold'>
          {driverSession?.firstName}
          {driverSession?.lastName}
        </p>
      </section>

      <section>
        <p>Correo: {driverSession?.email}</p>
        <p>Telefono: {driverSession?.phoneNumber}</p>
      </section>

      <section className='flex items-center gap-2'>
        <p
          className={`${
            driverSession?.isApproved ? 'text-green-500' : 'text-gray-500'
          }`}
        >
          {driverSession?.isApproved ? 'Usuario activo' : 'Pendiente'}
        </p>
        <p className='text-gray-500'>
          {driverSession?.isApproved
            ? 'Tu cuenta ha sido aprobada'
            : 'Tu cuenta esta pendiente de aprobación'}
        </p>
      </section>
      {/* <OrdersTab /> */}
      {/* <DriverAvailability /> */}
    </section>
  );
};

export default DriversDashboard;

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {toast, ToastContainer} from 'react-toastify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import 'react-day-picker/style.css';

import {
  AdminLoginPage,
  AdminProfilePage,
  FAQPage,
  PaymentPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
} from './routes/Routes';
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopAllProducts,
  ShopCreateEvents,
  ShopAllEvents,
  ShopAllCoupouns,
  ShopPreviewPage,
  ShopAllOrders,
  ShopOrderDetails,
  ShopAllRefunds,
  ShopSettingsPage,
  ShopStaffProfilePage,
  ShopWithDrawMoneyPage,
  ShopInboxPage,
} from './routes/ShopRoutes';
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw,
  AdminDashboardCategories,
  AdminDashboardBanners,
} from './routes/AdminRoutes';

import { loadSeller } from './redux/actions/user';
import ProtectedRoute from './routes/ProtectedRoute';
import ProtectedAdminRoute from './routes/ProtectedAdminRoute';
import { ShopHomePage } from './ShopRoutes';
import SellerProtectedRoute from './routes/SellerProtectedRoute';
import CreateStaff from './pages/Shop/Staff/CreateStaff/index';
import RoleList from './pages/Shop/Role/RoleList/index';
import CreateRole from './pages/Shop/Role/CreateRole/index';
import StaffList from './pages/Shop/Staff/StaffList/index';
import { getAdminUser } from './redux/actions/admin';
import { refreshConfiguration } from './redux/actions/config';
import DriverRoutes from './routes/drivers/DriverRoutes';
import { ADMIN_NAVIGATION } from './utils/constants/navigation';
import ShopCreatedSuccess from "./pages/ShopCreatedSuccess";
import {useAppSelector} from "./hooks/common/redux.hooks";
import {useAdminSessionStore} from "./store/adminSession";
import {adminLoginService, getAdminUserInfoService} from "./services/admin/auth";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "./components/UI/MKAlertDialog";
import ErrorAnimationState from "./components/Common/ErrorAnimation";

const App = () => {
  const [stripeApikey, setStripeApiKey] = useState('');
  
  const [unAuthorizedState, setUnAuthorizedState] = useState({ isUnAuthorized: false, errorMessage: '' });
  const { logoutAdmin, refreshAdminSession, token: tokenAdmin } = useAdminSessionStore();
  
  const dispatch = useDispatch();
  const { configuration } = useSelector((state) => state.configuration);
  

  useEffect(() => {
    dispatch(loadSeller());
    // dispatch(getAdminUser());
    // dispatch(getAllProducts());
    
    if (tokenAdmin) {
      handleUserAdminInformation();
    }
    
    if (!configuration) {
      dispatch(refreshConfiguration());
    }
    
  }, []);
  
  const handleLogoutByUnAuthorize = async () => {
    setUnAuthorizedState({isUnAuthorized: false, errorMessage: null });
    logoutAdmin();
  };
  
  const handleUserAdminInformation = async () => {

    const data = await getAdminUserInfoService();
    if (!data) {
      setUnAuthorizedState({ isUnAuthorized: true, errorMessage: 'Personalizado' });
      return;
    }
    if (data?.unauthorized === true) {
      setUnAuthorizedState({ isUnAuthorized: true, errorMessage: data.message });
      return;
    }
    
    if (data?.error === true) {
      toast.error(data?.message);
      return;
    }
    
    refreshAdminSession(data.admin);
  };

  return (
    <BrowserRouter>
      {stripeApikey && (
        <Elements stripe={loadStripe(stripeApikey)}>
          <Routes>
            <Route
              path='/payment'
              element={
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Elements>
      )}

      <Routes>
        <Route
          path='/'
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />

        {/*<Route path='/faq' element={<FAQPage />} />*/}

        {/* ADMIN ROUTES */}
        <>
          <Route path='/admin-login' element={<AdminLoginPage />} />

          <Route
            path='/admin-configuration'
            element={
              <ProtectedAdminRoute>
                <AdminProfilePage />
              </ProtectedAdminRoute>
            }
          />

          <Route
            path='/admin-categories'
            element={
              <ProtectedAdminRoute>
                <AdminDashboardCategories />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path='/admin-dashboard'
            element={
              <ProtectedAdminRoute>
                <AdminDashboardPage />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path='/admin-users'
            element={
              <ProtectedAdminRoute>
                <AdminDashboardUsers />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path='/admin-sellers'
            element={
              <ProtectedAdminRoute>
                <AdminDashboardSellers />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path='/admin-orders'
            element={
              <ProtectedAdminRoute>
                <AdminDashboardOrders />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path='/admin-products'
            element={
              <ProtectedAdminRoute>
                <AdminDashboardProducts />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path='/admin-events'
            element={
              <ProtectedAdminRoute>
                <AdminDashboardEvents />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path='/admin-withdraw-request'
            element={
              <ProtectedAdminRoute>
                <AdminDashboardWithdraw />
              </ProtectedAdminRoute>
            }
          />

          <Route
            path={ADMIN_NAVIGATION.banners.link}
            element={
              <ProtectedAdminRoute>
                <AdminDashboardBanners />
              </ProtectedAdminRoute>
            }
          />
        </>
        {/* END ADMIN ROUTES */}

        {/* SHOP ROUTES */}
        <>
          <Route
            path='/seller/activation/:activation_token'
            element={<SellerActivationPage />}
          />
          <Route
            path='/shop-profile'
            element={
              <SellerProtectedRoute>
                <ProfilePage />
              </SellerProtectedRoute>
            }
          />
          <Route path='/shop-success-create' element={<ShopCreatedSuccess />} />
          <Route path='/shop-create' element={<ShopCreatePage />} />
          <Route path='/shop-login' element={<ShopLoginPage />} />
          <Route
            path='/shop/:id'
            element={
              <SellerProtectedRoute>
                <ShopHomePage />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/profile'
            element={
              <SellerProtectedRoute>
                <ShopStaffProfilePage />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/shop-settings'
            element={
              <SellerProtectedRoute>
                <ShopSettingsPage />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/dashboard'
            element={
              <SellerProtectedRoute>
                <ShopDashboardPage />
              </SellerProtectedRoute>
            }
          />

          <Route
            path='/dashboard-products'
            element={
              <SellerProtectedRoute>
                <ShopAllProducts />
              </SellerProtectedRoute>
            }
          />

          <Route
            path='/dashboard-create-product'
            element={
              <SellerProtectedRoute>
                <ShopCreateProduct />
              </SellerProtectedRoute>
            }
          />

          <Route
            path='/dashboard-orders'
            element={
              <SellerProtectedRoute>
                <ShopAllOrders />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/dashboard-refunds'
            element={
              <SellerProtectedRoute>
                <ShopAllRefunds />
              </SellerProtectedRoute>
            }
          />

          <Route
            path='/dashboard-order/:id'
            element={
              <SellerProtectedRoute>
                <ShopOrderDetails />
              </SellerProtectedRoute>
            }
          />

          <Route
            path='/dashboard-create-event'
            element={
              <SellerProtectedRoute>
                <ShopCreateEvents />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/dashboard-events'
            element={
              <SellerProtectedRoute>
                <ShopAllEvents />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/dashboard-coupouns'
            element={
              <SellerProtectedRoute>
                <ShopAllCoupouns />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/dashboard-withdraw-money'
            element={
              <SellerProtectedRoute>
                <ShopWithDrawMoneyPage />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/dashboard-messages'
            element={
              <SellerProtectedRoute>
                <ShopInboxPage />
              </SellerProtectedRoute>
            }
          />
          {/* STAFF */}
          <Route
            path='/dashboard-create-staff'
            element={
              <SellerProtectedRoute>
                <CreateStaff />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/dashboard-my-staff'
            element={
              <SellerProtectedRoute>
                <StaffList />
              </SellerProtectedRoute>
            }
          />

          {/* Roles */}
          <Route
            path='/dashboard-roles'
            element={
              <SellerProtectedRoute>
                <RoleList />
              </SellerProtectedRoute>
            }
          />
          <Route
            path='/dashboard-create-rol'
            element={
              <SellerProtectedRoute>
                <CreateRole />
              </SellerProtectedRoute>
            }
          />
        </>
        {/* END SHOP ROUTES */}

        {/* Drivers ROUTES */}
        {DriverRoutes}

        <Route path='/shop/preview/:id' element={<ShopPreviewPage />} />
      </Routes>
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
      
      {/* === Dialog to Show UnAuthorized Session */}
      <>
        <AlertDialog open={unAuthorizedState.isUnAuthorized} >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Error
              </AlertDialogTitle>
              <>
                <ErrorAnimationState width={100} height={100} />
                <p className='text-center text-2xl text-primary-500 p-10'>
                  {unAuthorizedState.errorMessage}
                </p>
              </>
            </AlertDialogHeader>
            <AlertDialogFooter className='[&&]:justify-center' >
              <AlertDialogAction className='[&&]:self-center' onClick={() => handleLogoutByUnAuthorize() }>
                Iniciar session
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    </BrowserRouter>
  );
};

export default App;

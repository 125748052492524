import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { server } from '../server';
import LoadingStateAnimation from '../components/Common/LoadingState';
import ShopLoginHeader from "../components/ShopLoginHeader";

const SellerActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (activation_token) {
      const sendRequest = async () => {
        setLoading(true);
        setError(false);
        await axios
          .post(`${server}/shop/activation`, {
            activation_token,
          })
          .then((res) => {
            console.log(res);
            setLoading(false);
            setError(false);
            setSuccess(true);
          })
          .catch((err) => {
            setError(true);
            setLoading(false);
          });
      };
      sendRequest();
    }
  }, []);

  return (
    <>
      <section className='flex h-screen flex-col justify-start'>
        <ShopLoginHeader />
        <section>
          <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]'>
            <div className=' px-4 py-8 shadow sm:rounded-lg sm:px-10'>
              {/*{error && !loading && (*/}
              {/*  <p className='text-center text-primary-500'>*/}
              {/*    Ha ocurrido un error, intenta nuevamente*/}
              {/*  </p>*/}
              {/*)}*/}
              {false && (
                <>
                  <LoadingStateAnimation />
                  <p className='text-center text-primary-500'>
                    Creando cuenta...
                  </p>
                </>
              )}

              {true && (
                <section className='flex flex-col items-center justify-center'>
                  <p>Gracias por unirte a Merkoline</p>
                  <p>
                    Tu cuenta ha sido activada exitosamente, estás en lista de
                    espera a que el administración de Merkoline apruebe tu
                    afiliación!
                  </p>
                </section>
              )}

              <div className='w-full py-8 text-center md:py-12'>
                <Link to={'/shop-login'}>
                  <p className='text-primary-200'>Iniciar sesión</p>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default SellerActivationPage;

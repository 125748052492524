import axios from 'axios';
import { Link } from 'react-router-dom';
import { BsHandbag, BsCardImage } from 'react-icons/bs';
import { GrWorkshop } from 'react-icons/gr';
import { RxDashboard } from 'react-icons/rx';
import { FiShoppingBag } from 'react-icons/fi';
import { TbTruckDelivery } from 'react-icons/tb';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { BiCategoryAlt, BiMoneyWithdraw } from 'react-icons/bi';
import { AiOutlineLogout, AiOutlineSetting } from 'react-icons/ai';

import { server } from '../../../server';
import { ADMIN_NAVIGATION } from 'src/utils/constants/navigation';
import {useAdminSessionStore} from "../../../store/adminSession";

const AdminSideBar = ({ active }) => {

  const { logoutAdmin } = useAdminSessionStore();

  const logoutHandler = () => {
    logoutAdmin();
    axios.get(`${server}/admin/logout-admin`, {
      withCredentials: true,
    });
    // window.location.reload();

  };
  return (
    <div className='sticky left-0 top-0 z-10 h-[90vh] w-full overflow-y-scroll bg-white shadow-sm'>
      {/* single item */}
      <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.dashboard.link}
          className='flex w-full items-center'
        >
          <RxDashboard
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.dashboard.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.dashboard.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.users.link}
          className='flex w-full items-center'
        >
          <HiOutlineUserGroup
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.users.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.users.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Usuarios Admin
          </h5>
        </Link>
      </div>

      <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.sellers.link}
          className='flex w-full items-center'
        >
          <GrWorkshop
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.sellers.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.sellers.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Afiliados
          </h5>
        </Link>
      </div>

      <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.banners.link}
          className='flex w-full items-center'
        >
          <BsCardImage
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.banners.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.banners.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Banners
          </h5>
        </Link>
      </div>

      <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.drivers.link}
          className='flex w-full items-center'
        >
          <TbTruckDelivery
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.drivers.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.drivers.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Repartidores
          </h5>
        </Link>
      </div>

      <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.categories.link}
          className='flex w-full items-center'
        >
          <BiCategoryAlt
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.categories.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.categories.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Categorias
          </h5>
        </Link>
      </div>

      <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.orders.link}
          className='flex w-full items-center'
        >
          <FiShoppingBag
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.orders.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.orders.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Ordenes
          </h5>
        </Link>
      </div>

      {/* <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.products.link}
          className='flex w-full items-center'
        >
          <BsHandbag
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.products.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.products.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Productos
          </h5>
        </Link>
      </div> */}

      {/* <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.withdrawRequest.link}
          className='flex w-full items-center'
        >
          <BiMoneyWithdraw
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.withdrawRequest.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.withdrawRequest.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Petición de retiro
          </h5>
        </Link>
      </div> */}

      <div className='flex w-full items-center p-4'>
        <Link
          to={ADMIN_NAVIGATION.configuration.link}
          className='flex w-full items-center'
        >
          <AiOutlineSetting
            size={30}
            className={`${
              active === ADMIN_NAVIGATION.configuration.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === ADMIN_NAVIGATION.configuration.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Configuración
          </h5>
        </Link>
      </div>
      <div
        className='flex w-full cursor-pointer items-center p-4 text-slate-500'
        onClick={logoutHandler}
      >
        <AiOutlineLogout size={30} />
        <span className={`hidden pl-3 800px:block`}>Cerrar sesión</span>
      </div>
    </div>
  );
};

export default AdminSideBar;

import Lottie from 'react-lottie';
import animationData from '../../../Assests/animations/done-phone.json';

import { LottieAnimationProps } from '../../../interfaces/common';

const DonePhoneAnimationState = ({
  width = 250,
  height = 250,
  ...rest
}: LottieAnimationProps) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={width} height={height} />
    </div>
  );
};

export default DonePhoneAnimationState;

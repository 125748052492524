import axios from 'axios';
import { server, serverV3 } from '../../server';
import { Driver } from '../../interfaces/admin/driver';

export const findApprovedDriversService = async () => {
  try {
    const { data } = await axios.get(`${serverV3}/driver/approved-drivers`);
    return {
      success: data.success,
      drivers: data.drivers,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const findPotentialDriversService = async () => {
  try {
    const { data } = await axios.get(`${serverV3}/driver/potential-drivers`, {
      withCredentials: true,
    });
    return {
      success: data.success,
      potentialDrivers: data.potentialDrivers,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const approveDriverService = async (id: string) => {
  try {
    const { data } = await axios.patch(
      `${serverV3}/driver/drivers/${id}/approve`,
      {},
      {
        withCredentials: true,
      }
    );
    return {
      success: data.success,
      isApproved: data.isApproved,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const rejectDriverService = async (id: string) => {
  try {
    const { data } = await axios.patch(
      `${serverV3}/driver/drivers/${id}/reject`,
      {},
      {
        withCredentials: true,
      }
    );
    return {
      success: data.success,
      isApproved: data.isApproved,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const toggleDriverActiveStatus = async (
  id: string,
  isActive: boolean
) => {
  try {
    const { data } = await axios.patch(
      `${serverV3}/driver/toggle-active/${id}`,
      {
        isActive,
      },
      {
        withCredentials: true,
      }
    );
    return {
      success: data.success,
      isActive: data.isActive,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const findDriversByCityService = async (city: string) => {
  try {
    const { data } = await axios.post(`${serverV3}/driver/drivers-by-city`, {
      city,
    });
    return {
      success: data.success,
      drivers: data.drivers,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const findDriversBySpecificOrderService = async (orderId: string) => {
  try {
    const { data } = await axios.get(
      `${serverV3}/driver/available-drivers-to-shipment/${orderId}`,
      {
        withCredentials: true,
      }
    );
    return {
      success: data.success,
      drivers: data.potentialDrivers,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const uploadDriverProfile = async (file: any) => {
  try {
    const { data } = await axios.post(
      `${serverV3}/upload-image/driver-profile`,
      file,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    console.log('uploadDriverProfile', data);

    return {
      success: data.success,
      data: data.token,
      url: data.url,
    };
  } catch (error) {
    return {
      success: false,
      error: error?.message,
    };
  }
};

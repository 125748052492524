import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { IFetchState } from '../interfaces/common';
import { IAdminUser } from 'src/interfaces/admin/admin';

/**
 * Whathever information check de driverSession Implementation
 */

interface AdminSessionStore {
  adminSession: IAdminUser | null;
  adminFetchState: IFetchState;
  token: string | null;
  setAdminLoginLoading: (loading?: boolean) => void;
  setAdminLoginError: (error?: boolean, message?: string) => void;
  refreshAdminSession: (driver?: IAdminUser, token?: string) => void;
  singInAdmin: (driver: IAdminUser, token: string) => void;
  logoutDriver: () => void;
  clearAdminLoginState: () => void;
  logoutAdmin: () => void;
}

export const useAdminSessionStore = create<AdminSessionStore>()(
  persist(
    (set) => ({
      adminSession: null,
      adminFetchState: { loading: false, error: false },
      token: null,
      singInAdmin: (admin: IAdminUser, token) =>
        set({ adminSession: admin, token }),
      refreshAdminSession: (admin?: IAdminUser, token?) => {
        if (admin && token) {
          set({ adminSession: admin, token });
          return;
        }
        
        if (token) {
          set({ token });
          return;
        }
        
        if (admin) {
          set({ adminSession: admin });
          return;
        }
      },
      logoutAdmin: () => set({ adminSession: null, token: null, adminFetchState: null }),
      setAdminLoginLoading: (loading = true) =>
        set({
          adminFetchState: { loading: loading, error: false, message: '' },
        }),
      setAdminLoginError: (error = true, message = '') =>
        set({ adminFetchState: { loading: false, error: error, message } }),
      logoutDriver: () => set({ adminSession: null }),
      clearAdminLoginState: () =>
        set({
          adminFetchState: { loading: false, error: false, message: null },
        }),
    }),
    {
      name: 'admin-login-session',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

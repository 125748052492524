import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLogin from '../../components/Login/AdminLogin';
import { useAppSelector } from '../../hooks/common/redux.hooks';

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAppSelector((state) => state.admin);

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate('/admin-dashboard');
    }
  }, [isAuthenticated]);

  return (
    <div>
      <AdminLogin />
    </div>
  );
};

export default AdminLoginPage;

import axios from 'axios';
import { server } from '../../server';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants/common';
import { ILoginAdmin } from 'src/interfaces/admin/admin';

export const adminLoginService = async ({ email, password }: ILoginAdmin) => {
  try {
    const { data } = await axios.post(`${server}/admin/login-admin`, {
      email,
      password,
    },
    { withCredentials: true });
    return data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};


export const getAdminUserInfoService = async () => {
  try {
    const { data } = await axios.get(`${server}/admin/load-admin`,
      { withCredentials: true });
    return data;
  } catch (error: any) {
    
    const defaultErrorResponse = {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
    
    return validateAuthenticationError(error, defaultErrorResponse)
  }
};

const validateAuthenticationError = (error: any, response: any) => {
  if (error.response) {
    const defaultErrorResponse = {
      error: true,
      message: error.response?.data?.message || 'Ocurrio un error',
      unauthorized: true,
    }
    if (error.response.status === 401) {
      defaultErrorResponse.message = error.response?.data?.message ||
        'OPs hemos tenido un problema por favor vuelve a iniciar sessión';
    }
    
    return defaultErrorResponse;
  } else {
    return response;
  }
}

import React from 'react';
import {
  FaChevronRight,
  FaCity,
  FaMapMarkedAlt,
  FaMapPin,
} from 'react-icons/fa';
import {
  MdMail,
  MdOutlineHideImage,
  MdOutlinePhoneAndroid,
} from 'react-icons/md';
import MKCard from '../../../UI/MKCard';
import { IoMdMore } from 'react-icons/io';
import {
  MKMenubar,
  MKMenubarContent,
  MKMenubarItem,
  MKMenubarMenu,
  MKMenubarTrigger,
} from '../../../UI/MKMenuBar';
import { Link, useNavigate } from 'react-router-dom';
interface ISinglePartner {
  partner: any;
}
const SinglePartner = ({ partner }: ISinglePartner) => {
  const navigate = useNavigate();

  const goToDetail = () => {
    navigate(`/shop/preview/${partner._id}`);
  };
  return (
    <section>
      <MKCard className='flex flex-col gap-4 text-left'>
        <section className='flex justify-between border-b pb-2'>
          <section className='flex items-center gap-2 '>
            {partner.avatar?.url ? (
              <img src={partner.avatar.url} className='h-12 w-12' alt='' />
            ) : (
              <MdOutlineHideImage className='h-8 w-8 text-gray-500' />
            )}
            <p className='text-lg font-bold'>{partner.name}</p>
          </section>
          <section>
            <MKMenubar>
              <MKMenubarMenu>
                <MKMenubarTrigger>
                  <div className='rounded-full px-2 py-2'>
                    <IoMdMore className='text-primary-500' size={20} />
                  </div>
                </MKMenubarTrigger>

                <MKMenubarContent className='bg-white'>
                  <MKMenubarItem>
                    <Link
                      to={`/shop/preview/${partner._id}`}
                      className='flex w-full items-center justify-between'
                    >
                      <p>Ver detalles</p>
                      <FaChevronRight />
                    </Link>
                  </MKMenubarItem>
                </MKMenubarContent>
              </MKMenubarMenu>
            </MKMenubar>
          </section>
        </section>

        <button onClick={goToDetail} className='flex flex-wrap gap-2 md:gap-8'>
          {/* general info */}
          <section className='flex w-full flex-col gap-2 sm:w-auto  md:flex-row md:gap-8'>
            <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
              <div className='rounded-lg bg-primary-50 p-2'>
                <MdMail className='text-primary-500' />
              </div>
              <div>
                <p className='text-left font-semibold'>Correo</p>
                <p className='text-gray-700'>{partner.email}</p>
              </div>
            </section>
            <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
              <div className='rounded-lg bg-primary-50 p-2'>
                <MdOutlinePhoneAndroid className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Teléfono</p>
                <p className='text-gray-700'>{partner.phoneNumber}</p>
              </div>
            </section>
          </section>

          {/* location info */}
          <section className='flex w-full  flex-col gap-2 sm:w-auto md:flex-row md:gap-8'>
            <div className='flex items-center gap-2  rounded-lg border border-dashed p-2 md:px-4'>
              <div className='rounded-lg bg-primary-50 p-2'>
                <FaMapMarkedAlt className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Departamento</p>
                <p className='text-gray-700'>{partner?.address?.region}</p>
              </div>
            </div>
            <div className='flex items-center gap-2  rounded-lg border border-dashed p-2 md:px-4'>
              <div className='rounded-lg bg-primary-50 p-2'>
                <FaCity className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Ciudad</p>
                <p className='text-gray-700'>{partner?.address?.city}</p>
              </div>
            </div>
            <div className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
              <div className='rounded-lg bg-primary-50 p-2'>
                <FaMapPin className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Barrio / sector</p>
                <p className='text-gray-700'>{partner?.address?.neighborhood}</p>
              </div>
            </div>
          </section>
        </button>
      </MKCard>
    </section>
  );
};

export default SinglePartner;

import { create } from 'zustand';
import { IFetchState } from '../interfaces/common';
import { Driver } from '../interfaces/admin/driver';

interface DriverStore {
  drivers: Driver[] | null;
  potentialDrivers: Driver[] | null;
  driverState: IFetchState;
  potentialDriverState: IFetchState;
  setDrivers: (allCategories: Driver[]) => void;
  setDriverLoading: (loading?: boolean) => void;
  setDriverError: (error?: boolean, message?: string) => void;
  clearDriverState: () => void;
  setPotentialDrivers: (potentialDriver: Driver[]) => void;
  setPotentialDriversLoading: (loading?: boolean) => void;
  setPotentialDriversError: (error?: boolean, message?: string) => void;
  clearPotentialDriversState: () => void;
}

export const useDriverStore = create<DriverStore>((set) => ({
  driverAuthenticated: null,
  drivers: null,
  potentialDrivers: null,
  driverState: { loading: false, error: false },
  potentialDriverState: { loading: false, error: false },
  setDrivers: (activeDrivers: Driver[]) =>
    set({
      drivers: activeDrivers,
      driverState: { loading: false, error: false },
    }),
  setDriverLoading: (loading = true) =>
    set({ driverState: { loading: loading, error: false, message: '' } }),
  setDriverError: (error = true, message = '') =>
    set({ driverState: { loading: false, error: error, message } }),
  clearDriverState: () =>
    set({ driverState: { loading: false, error: false, message: null } }),

  setPotentialDrivers: (activeDrivers: Driver[]) =>
    set({
      potentialDrivers: activeDrivers,
      potentialDriverState: { loading: false, error: false },
    }),
  setPotentialDriversLoading: (loading = true) =>
    set({
      potentialDriverState: { loading: loading, error: false, message: '' },
    }),
  setPotentialDriversError: (error = true, message = '') =>
    set({ potentialDriverState: { loading: false, error: error, message } }),
  clearPotentialDriversState: () =>
    set({
      potentialDriverState: { loading: false, error: false, message: null },
    }),
}));

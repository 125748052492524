import { Navigate } from 'react-router-dom';
import {useAdminSessionStore} from "../store/adminSession";
import Cookies from "js-cookie";

const ProtectedAdminRoute = ({ children }) => {

  const { adminSession, adminFetchState, token } = useAdminSessionStore();

  console.log('adminFetchState:', adminFetchState);
  console.log('adminSession:', adminSession);

  if (!adminFetchState?.loading) {
    if (!adminSession) {
      return <Navigate to='/admin-login' replace />;
    }

    Cookies.set('token', token);
    return children;
  }

  return <></>;
};

export default ProtectedAdminRoute;

import {Link} from "react-router-dom";
import React from "react";


const ShopLoginHeader = () => {
    return (
        <section className='mx-auto flex h-14 w-full items-center bg-black px-2'>
            <div className='mx-auto w-2/3'>
                <p className='text-primary-50'>
                    Quires ser parte del equipo de entregas?{' '}
                    <Link className='text-primary-200' to={'/drivers-signin'}>
                        Únete al equipo
                    </Link>
                </p>
            </div>
        </section>
    );
}

export default ShopLoginHeader;
import React, { useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ShopLoginHeader from "../components/ShopLoginHeader";
import LoadingStateAnimation from "../components/Common/LoadingState";

const ShopCreatedSuccess = () => {

  return (
      <>
        <section className='flex h-screen flex-col justify-start'>
          <ShopLoginHeader />
          <section>
            <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem] bg-amber-300'>
              <div className=' px-4 py-8 shadow sm:rounded-lg sm:px-10'>
                {/*{error && !loading && (*/}
                {/*  <p className='text-center text-primary-500'>*/}
                {/*    Ha ocurrido un error, intenta nuevamente*/}
                {/*  </p>*/}
                {/*)}*/}
                {false && (
                    <>
                      <LoadingStateAnimation />
                      <p className='text-center text-primary-500'>
                        Creando cuenta...
                      </p>
                    </>
                )}

                {true && (
                    <section className='flex flex-col items-center justify-center'>
                      <div className='flex flex-wrap items-center justify-center'>
                        <p
                            className='text-center text-3xl font-black'
                        >
                          Gracias por unirte a Merkoline
                        </p>
                      </div>
                      <div className='p-10'>
                        <p className='text-center'>
                          Tu cuenta ha sido activada exitosamente, estás en lista de
                          espera a que el administración de Merkoline apruebe tu
                          afiliación!
                        </p>
                      </div>
                    </section>
                )}

                <div className='w-full py-8 text-center md:py-12'>
                  <Link to={'/shop-login'}>
                    <p className='text-primary-200'>Iniciar sesión</p>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
  );
};

export default ShopCreatedSuccess;

import { BsShop } from 'react-icons/bs';
import DashboardCommonLayout from '../../../components/Layout/DashboardCommonLayout';
import DashboardSideBar from '../../../components/Shop/Layout/DashboardSideBar';
import { useAppSelector } from '../../../hooks/common/redux.hooks';
import ShopInfo from '../../../components/Shop/ShopInfo/ShopInfo';
import styles from '../../../styles/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import MKButton from '../../../components/UI/MKButton';
import { IShop } from '../../../interfaces/shop/shop';

const ShopSettingsPage = () => {
  const { seller } = useAppSelector((state) => state.seller);
  console.log('seller', seller);
  const { register, handleSubmit } = useForm<IShop>({
    defaultValues: {
      name: seller.shop.name,
    },
  });

  const onSubmit: SubmitHandler<IShop> = async (data) => {
    console.log('submit', data);
  };

  return (
    <DashboardCommonLayout>
      <div className='flex w-full justify-between'>
        <div className='w-[80px] 800px:w-[330px]'>
          <DashboardSideBar active={11} />
        </div>
        <div className={`${styles.section}`}>
          <div className='flex w-full flex-col justify-between gap-2 py-10 md:gap-4'>
            <div className='sticky left-0 top-10 z-10 w-full overflow-y-scroll rounded-md bg-slate-50 shadow-md'>
              <ShopInfo data={seller.shop} isOwner={false} />
            </div>
          </div>
          <form action='' onSubmit={handleSubmit(onSubmit)}>
            <section className='flex w-full flex-col gap-4'>
              <div className='flex flex-col gap-2'>
                <p className='text-lg font-semibold'>
                  Información de la tienda
                </p>
                <input
                  className='placeholder:text-muted-foreground flex h-12 w-full rounded-xl border border-input px-4 py-2 text-sm ring-offset-background file:border-0 file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50'
                  {...register('name')}
                  placeholder='Shop name'
                />
              </div>
              <div className='flex flex-col gap-2'>
                <p className='text-lg font-semibold'>Foto de la tienda</p>
                {seller.shop?.avatar?.url ? (
                  <img
                    src={`${seller.shop.avatar.url}`}
                    alt=''
                    className='h-20 w-20 rounded-full object-cover'
                  />
                ) : (
                  <BsShop className='h-[28px] w-[28px] text-gray-700' />
                )}
              </div>
            </section>
            <MKButton type='submit'>Guardar</MKButton>
          </form>
        </div>
      </div>
    </DashboardCommonLayout>
  );
};

export default ShopSettingsPage;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/styles';
import { categoriesData } from '../../static/data';
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { BiMenuAltLeft } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import DropDown from './DropDown';
import Navbar from './Navbar';
import { useSelector } from 'react-redux';
import Cart from '../cart/Cart';
import Wishlist from '../Wishlist/Wishlist';
import { RxCross1 } from 'react-icons/rx';

const Header = ({ activeHeading }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  window.addEventListener('scroll', () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return (
    <>
      <div className={`${styles.section}`}>
        <div className='hidden items-center justify-between 800px:my-[20px] 800px:flex 800px:h-[50px]'>
          <div>
            <Link to='/admin-dashboard'>
              <img
                  className='h20 w-20 p-2 cursor-pointer'
                  src='/assets/merkoline_logo.svg'
                  alt=''
              />
            </Link>
          </div>
          {/* search box */}
          <div className='relative w-[50%]'>
            <input
              type='text'
              placeholder='Search Product...'
              value={searchTerm}
              onChange={handleSearchChange}
              className='h-[40px] w-full rounded-md border-[2px] border-[#3957db] px-2'
            />
            <AiOutlineSearch
              size={30}
              className='absolute right-2 top-1.5 cursor-pointer'
            />
            {searchData && searchData.length !== 0 ? (
              <div className='shadow-sm-2 absolute z-[9] min-h-[30vh] bg-slate-50 p-4'>
                {searchData &&
                  searchData.map((i, index) => {
                    return (
                      <Link to={`/product/${i._id}`}>
                        <div className='items-start-py-3 flex w-full'>
                          <img
                            src={`${i.images[0]?.url}`}
                            alt=''
                            className='mr-[10px] h-[40px] w-[40px]'
                          />
                          <h1>{i.name}</h1>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            ) : null}
          </div>

          <div className={`${styles.button}`}>
            <Link to={`${isSeller ? '/dashboard' : '/shop-create'}`}>
              <h1 className='flex items-center text-[#fff]'>
                {isSeller ? 'Go Dashboard' : 'Become Seller'}{' '}
                <IoIosArrowForward className='ml-1' />
              </h1>
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`${
          active === true ? 'fixed left-0 top-0 z-10 shadow-sm' : null
        } hidden h-[70px] w-full items-center justify-between bg-[#3321c8] transition 800px:flex`}
      >
        <div
          className={`${styles.section} relative ${styles.noramlFlex} justify-between`}
        >
          {/* categories */}
          <div onClick={() => setDropDown(!dropDown)}>
            <div className='relative mt-[10px] hidden h-[60px] w-[270px] 1000px:block'>
              <BiMenuAltLeft size={30} className='absolute left-2 top-3' />
              <button
                className={`font-sans flex h-[100%] w-full select-none items-center justify-between rounded-t-md bg-white pl-10 text-lg font-[500]`}
              >
                All Categories
              </button>
              <IoIosArrowDown
                size={20}
                className='absolute right-2 top-4 cursor-pointer'
                onClick={() => setDropDown(!dropDown)}
              />
              {dropDown ? (
                <DropDown
                  categoriesData={categoriesData}
                  setDropDown={setDropDown}
                />
              ) : null}
            </div>
          </div>
          {/* navitems */}
          <div className={`${styles.noramlFlex}`}>
            <Navbar active={activeHeading} />
          </div>

          <div className='flex'>
            <div className={`${styles.noramlFlex}`}>
              <div
                className='relative mr-[15px] cursor-pointer'
                onClick={() => setOpenWishlist(true)}
              >
                <AiOutlineHeart size={30} color='rgb(255 255 255 / 83%)' />
                <span className='top right font-mono absolute right-0 top-0 m-0 h-4 w-4 rounded-full bg-[#3bc177] p-0 text-center text-[12px] leading-tight text-white'>
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div
                className='relative mr-[15px] cursor-pointer'
                onClick={() => setOpenCart(true)}
              >
                <AiOutlineShoppingCart
                  size={30}
                  color='rgb(255 255 255 / 83%)'
                />
                <span className='top right font-mono absolute right-0 top-0 m-0 h-4 w-4 rounded-full bg-[#3bc177] p-0 text-center text-[12px] leading-tight text-white'>
                  {cart && cart.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div className='relative mr-[15px] cursor-pointer'>
                {isAuthenticated ? (
                  <Link to='/profile'>
                    <img
                      src={`${user?.avatar?.url}`}
                      className='h-[35px] w-[35px] rounded-full'
                      alt=''
                    />
                  </Link>
                ) : (
                  <Link to='/login'>
                    <CgProfile size={30} color='rgb(255 255 255 / 83%)' />
                  </Link>
                )}
              </div>
            </div>

            {/* cart popup */}
            {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

            {/* wishlist popup */}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${
          active === true ? 'fixed left-0 top-0 z-10 shadow-sm' : null
        }
      left-0 top-0 z-50 h-[60px] w-full bg-[#fff] shadow-sm 800px:hidden`}
      >
        <div className='flex w-full items-center justify-between'>
          <div>
            <BiMenuAltLeft
              size={40}
              className='ml-4'
              onClick={() => setOpen(true)}
            />
          </div>
          <div>
            <Link to='/'>
              <img
                  className='h20 w-20 p-2 cursor-pointer'
                  src='/assets/merkoline_logo.svg'
                  alt=''
              />
            </Link>
          </div>
          <div>
            <div
              className='relative mr-[20px]'
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} />
              <span class='top right font-mono absolute right-0 top-0 m-0 h-4 w-4 rounded-full bg-[#3bc177] p-0 text-center text-[12px]  leading-tight text-white'>
                {cart && cart.length}
              </span>
            </div>
          </div>
          {/* cart popup */}
          {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

          {/* wishlist popup */}
          {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
        </div>

        {/* header sidebar */}
        {open && (
          <div
            className={`fixed left-0 top-0 z-20 h-full w-full bg-[#0000005f]`}
          >
            <div className='fixed left-0 top-0 z-10 h-screen w-[70%] overflow-y-scroll bg-[#fff]'>
              <div className='flex w-full justify-between pr-3'>
                <div>
                  <div
                    className='relative mr-[15px]'
                    onClick={() => setOpenWishlist(true) || setOpen(false)}
                  >
                    <AiOutlineHeart size={30} className='ml-3 mt-5' />
                    <span class='top right font-mono absolute right-0 top-0 m-0 h-4 w-4 rounded-full bg-[#3bc177] p-0 text-center text-[12px]  leading-tight text-white'>
                      {wishlist && wishlist.length}
                    </span>
                  </div>
                </div>
                <RxCross1
                  size={30}
                  className='ml-4 mt-5'
                  onClick={() => setOpen(false)}
                />
              </div>

              <div className='h-[40px relative] m-auto my-8 w-[92%]'>
                <input
                  type='search'
                  placeholder='Search Product...'
                  className='h-[40px] w-full rounded-md border-[2px] border-[#3957db] px-2'
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {searchData && (
                  <div className='absolute left-0 z-10 w-full bg-[#fff] p-3 shadow'>
                    {searchData.map((i) => {
                      const d = i.name;

                      const Product_name = d.replace(/\s+/g, '-');
                      return (
                        <Link to={`/product/${Product_name}`}>
                          <div className='flex items-center'>
                            <img
                              src={i.image_Url[0]?.url}
                              alt=''
                              className='mr-2 w-[50px]'
                            />
                            <h5>{i.name}</h5>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>

              <Navbar active={activeHeading} />
              <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to='/shop-create'>
                  <h1 className='flex items-center text-[#fff]'>
                    Become Seller <IoIosArrowForward className='ml-1' />
                  </h1>
                </Link>
              </div>
              <br />
              <br />
              <br />

              <div className='flex w-full justify-center'>
                {isAuthenticated ? (
                  <div>
                    <Link to='/profile'>
                      <img
                        src={`${user.avatar?.url}`}
                        alt=''
                        className='h-[60px] w-[60px] rounded-full border-[3px] border-[#0eae88]'
                      />
                    </Link>
                  </div>
                ) : (
                  <>
                    <Link
                      to='/login'
                      className='pr-[10px] text-[18px] text-[#000000b7]'
                    >
                      Login /
                    </Link>
                    <Link
                      to='/sign-up'
                      className='text-[18px] text-[#000000b7]'
                    >
                      Sign up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;

import { ButtonVariantType } from '../../../types/common';

export const defaultButtonVariantStyle = (variant?: ButtonVariantType) => {
  const baseStyle = 'rounded-xl self-start px-4 py-2';
  
  const variantStyle = {
    none: '',
    outlined:
      `border border-primary-200 hover:bg-gray-100 text-primary-500 focus:outline-none ${baseStyle}`,
    contained:
      `bg-primary-200 hover:bg-primary-500 text-white ${baseStyle}`,
    text: 'bg-transparent self-start px-2 py-1',
    default:
      `bg-primary-200 hover:bg-primary-500 text-white ${baseStyle}`,
    ghost: 'hover:bg-accent hover:text-accent-foreground',
  };
  
  return variantStyle[variant || 'default'];
};
import React, { useEffect } from 'react';
import AdminHeader from '../components/Layout/AdminHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import { DataGrid } from '@material-ui/data-grid';
import { getAllOrdersOfAdmin } from '../redux/actions/order';
import { useAppDispatch, useAppSelector } from '../hooks/common/redux.hooks';
import MKCard from '../components/UI/MKCard';
import { Link } from 'react-router-dom';
import { MdOutlineHideImage } from 'react-icons/md';
import { ORDER_STATUS } from '../utils/constants/common';

const AdminDashboardOrders = () => {
  const dispatch = useAppDispatch();

  const { adminOrders, adminOrderLoading } = useAppSelector(
    (state) => state.order
  );
  const PRODUCT_IMAGES_TO_SHOW = 3;

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, []);

  console.log('adminOrders:', adminOrders);

  return (
    <div>
      <AdminHeader />
      <div className='flex w-full'>
        <div className='flex w-full items-start justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <AdminSideBar active={2} />
          </div>

          <div className='flex min-h-[45vh] w-full justify-center rounded pt-5'>
            <div className='w-full'>
              <p className='text-center text-lg font-bold'>Ordenes</p>
              {adminOrders.map((order) => (
                <MKCard className='my-2'>
                  <section>
                    {order.shop && (
                      <div className='my-2 flex items-center gap-2'>
                        {order.shop.avatar?.url ? (
                          <img
                            className='h-12 w-12 object-contain'
                            src={order.shop.avatar?.url}
                            alt={order.shop.name}
                          />
                        ) : (
                          <MdOutlineHideImage className='h-12 w-12 text-gray-300' />
                        )}
                        <p className='text-sm font-bold text-primary-500'>
                          {order.shop.name}
                        </p>
                      </div>
                    )}
                    <p className='text-sm font-bold text-primary-200'>
                      {ORDER_STATUS[order.status]?.label}
                    </p>
                    <p>
                      {order?.deliveryDate &&
                        new Intl.DateTimeFormat('es', {
                          weekday: 'long',
                          day: '2-digit',
                          month: 'long',
                          year: 'numeric',
                        }).format(new Date(order?.deliveryDate))}
                    </p>
                    <section>
                      {Array.isArray(order?.cart) && (
                        <div className='flex flex-row gap-4'>
                          {[...order.cart.slice(0, PRODUCT_IMAGES_TO_SHOW)].map(
                            (product) => (
                              <div className='rounded-full border p-3'>
                                {Array.isArray(product.images) &&
                                product.images.length > 0 ? (
                                  <img
                                    className='h-12 w-12 object-contain'
                                    src={product.images[0].url}
                                    alt={product.name}
                                  />
                                ) : (
                                  <MdOutlineHideImage className='h-12 w-12 text-gray-300' />
                                )}
                              </div>
                            )
                          )}
                          {order.cart.length >= PRODUCT_IMAGES_TO_SHOW + 1 && (
                            <div className=' rounded-full border p-3'>
                              <div className='flex h-12 w-12 items-center justify-center text-center'>
                                +{order.cart.length - PRODUCT_IMAGES_TO_SHOW}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </section>
                    <div className='mt-4 flex flex-row items-center'>
                      <div>
                        <p>Total: C$ {order.totalPrice.toFixed(2)}</p>
                      </div>
                      {/* <div className='flex'>
                        <button className='ml-4 rounded-md bg-primary-200 p-2 text-white'>
                          Ver detalle
                        </button>
                      </div> */}
                    </div>
                  </section>
                </MKCard>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardOrders;

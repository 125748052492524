import { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import styles from '../../styles/styles';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { loadSeller } from '../../redux/actions/user';
import { staffSignInService } from '../../services/staff/auth';
import ShopLoginHeader from "../ShopLoginHeader";

const ShopLogin = () => {
  const navigate = useNavigate();
  const seller = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await staffSignInService(email, password);

    if (!result.success) {
      toast.error(result.errorMessage || 'Ha ocurrido un error inesperado');
      return;
    }
    toast.success('Inicio de sesión exitoso!');
    navigate('/dashboard');

    window.location.reload(true);
  };
  if (seller) {
    console.log('Success seller:', seller);
  }

  return (
    <section className='flex h-screen flex-col '>
      <ShopLoginHeader />
      <div className='mx-auto h-full w-full flex-1 p-4 text-lg md:w-2/3 '>
        <div className='flex h-full w-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <section>
            <div className='sm:mx-auto sm:w-full sm:max-w-md'>
              <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
                Inicia sesión y administra tu negocio
              </h2>
            </div>
            <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
              <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
                <form className='space-y-6' onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Correo
                    </label>
                    <div className='mt-1'>
                      <input
                        type='email'
                        name='email'
                        autoComplete='email'
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`${styles.default_input}`}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor='password'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Contraseña
                    </label>
                    <div className='relative mt-1'>
                      <input
                        type={visible ? 'text' : 'password'}
                        name='password'
                        autoComplete='current-password'
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`${styles.default_input}`}
                      />
                      {visible ? (
                        <AiOutlineEye
                          className='absolute right-2 top-2 cursor-pointer'
                          size={25}
                          onClick={() => setVisible(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className='absolute right-2 top-2 cursor-pointer'
                          size={25}
                          onClick={() => setVisible(true)}
                        />
                      )}
                    </div>
                  </div>
                  <div className={`${styles.noramlFlex} justify-between`}>
                    <div className={`${styles.noramlFlex}`}>
                      <input
                        type='checkbox'
                        name='remember-me'
                        id='remember-me'
                        className='h-4 w-4 rounded border-gray-300 text-primary-200 focus:ring-primary-200'
                      />
                      <label
                        htmlFor='remember-me'
                        className='ml-2 block text-sm text-gray-900'
                      >
                        Recordarme
                      </label>
                    </div>
                    <div className='text-sm'>
                      <a
                        href='/forgot-password'
                        className='font-medium text-primary-200 hover:text-primary-200'
                      >
                        ¿Olvidaste tu contraseña?
                      </a>
                    </div>
                  </div>
                  <div>
                    <button
                      type='submit'
                      className='group relative flex h-[40px] w-full justify-center rounded-md border border-transparent bg-primary-200 px-4 py-2 text-sm font-medium text-white hover:bg-primary-500'
                    >
                      Iniciar sesión
                    </button>
                  </div>
                  <div className={`${styles.noramlFlex} w-full`}>
                    <h4>¿No tienes cuenta?</h4>
                    <Link to='/shop-create' className='pl-2 text-primary-200'>
                      Registrate
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default ShopLogin;

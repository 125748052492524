import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineCamera } from 'react-icons/ai';
import { server } from '../../server';
import styles from '../../styles/styles';
import { loadSeller } from '../../redux/actions/user';
import MKCard from '../UI/MKCard';

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState(seller && seller.name);
  const [description, setDescription] = useState(
    seller && seller.lastName ? seller.lastName : ''
  );
  const [address, setAddress] = useState(seller && seller.address);
  const [phoneNumber, setPhoneNumber] = useState(seller && seller.phoneNumber);
  const zipCode = seller && seller.zipCode;

  const dispatch = useDispatch();

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/shop/update-shop-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            dispatch(loadSeller());
            toast.success('Avatar updated successfully!');
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/shop/update-seller-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success('Shop info updated succesfully!');
        dispatch(loadSeller());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <MKCard>
      <div className='flex w-full flex-col items-center'>
        <div className='my-3 flex w-full flex-col justify-center'>
          <div className='flex w-full items-center justify-center'>
            <div className='relative'>
              <img
                src={avatar ? avatar : `${seller?.shop.avatar?.url}`}
                alt=''
                className='h-[200px] w-[200px] cursor-pointer rounded-full border'
              />
              <div className='absolute bottom-[10px] right-[15px] flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full bg-[#E3E9EE]'>
                <input
                  type='file'
                  id='image'
                  className='hidden'
                  onChange={handleImage}
                />
                <label htmlFor='image'>
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>

          {/* shop info */}
          <form
            aria-aria-required={true}
            className='flex flex-col items-center'
            onSubmit={updateHandler}
          >
            <div className='mt-5 flex w-[100%] flex-col items-center 800px:w-[50%]'>
              <div className='w-full pl-[3%]'>
                <label className='block pb-2'>Nombre</label>
              </div>
              <input
                type='name'
                placeholder={`${seller.name}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`${styles.input} mb-4 !w-[95%] 800px:mb-0`}
                required
              />
            </div>
            <div className='mt-5 flex w-[100%] flex-col items-center 800px:w-[50%]'>
              <div className='w-full pl-[3%]'>
                <label className='block pb-2'>Apellido</label>
              </div>
              <input
                type='name'
                placeholder={`${
                  seller?.lastname ? seller.lastname : 'Apellido'
                }`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`${styles.input} mb-4 !w-[95%] 800px:mb-0`}
              />
            </div>
            <div className='mt-5 flex w-[100%] flex-col items-center 800px:w-[50%]'>
              <div className='w-full pl-[3%]'>
                <label className='block pb-2'> Dirección</label>
              </div>
              <input
                type='name'
                placeholder={seller?.address}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={`${styles.input} mb-4 !w-[95%] 800px:mb-0`}
                required
              />
            </div>

            <div className='mt-5 flex w-[100%] flex-col items-center 800px:w-[50%]'>
              <div className='w-full pl-[3%]'>
                <label className='block pb-2'>Teléfono de tienda</label>
              </div>
              <input
                type='number'
                placeholder={seller?.phoneNumber}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={`${styles.input} mb-4 !w-[95%] 800px:mb-0`}
                required
              />
            </div>

            <div className='mt-5 flex w-[100%] flex-col items-center 800px:w-[50%]'>
              <input
                type='submit'
                value='Guardar cambios'
                className={`${styles.button} mb-4 !w-[95%] 800px:mb-0`}
                required
                readOnly
              />
            </div>
          </form>
        </div>
      </div>
    </MKCard>
  );
};

export default ShopSettings;

import axios from 'axios';
import { server } from '../../server';

export const staffSignInService = async (email: string, password: string) => {
  try {
    const response = await axios.post(
      `${server}/shop/login-shop`,
      {
        email,
        password,
      },
      { withCredentials: true }
    );

    if (response.status !== 200) {
      return {
        success: false,
        errorMessage: response?.data?.message,
      };
    }

    return {
      success: response?.data?.success,
      data: response?.data?.seller,
      errorMessage: response?.data?.message,
    };
  } catch (error) {
    let errorMessage = error?.message;
    if (axios.isAxiosError(error)) {
      errorMessage = error?.response?.data?.message;
    }
    return {
      success: false,
      errorMessage: errorMessage,
    };
  }
};

import axios from 'axios';
import {server, serverV3} from '../../server';
import { IShop } from '../../interfaces/shop/shop';
import {CreateDriverForm} from "../../interfaces/admin/driver";
import {DEFAULT_ERROR_MESSAGE} from "../../utils/constants/common";

export interface ICreateShop {
  name?: string;
  email?: string;
  password?: string;
  tokenImage?: string;
  address?: IShopAddress;
  countryCode?: string;
  phoneNumber?: string;
  cityId?: string;
}

export interface IShopAddress {
  type: string;
  coordinates: number[];
  neighborhood?: string;
  city?: string;
  region?: string;
}

export const uploadShopProfileService = async (file: any) => {
  try {
    const { data } = await axios.post(
        `${serverV3}/upload-image/shop-profile`,
        file,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
    );
    console.log('uploadShopProfile', data);

    return {
      success: data.success,
      data: data.token,
    };
  } catch (error) {
    return {
      success: false,
      error: error?.message,
    };
  }
};

export const signUpShopService = async (props: ICreateShop) => {
  try {
    const response = await axios.post(
        `${serverV3}/shops/create-shop`,
        props
    );
    return {
      success: true,
      data: response?.data,
      message: null,
    };
  } catch (error: any) {
    console.log('error:', error);
    const requestError = error?.response?.data?.message || error?.message;
    return {
      success: false,
      message: requestError || DEFAULT_ERROR_MESSAGE,
    };
  }
};

export const updateShopService = async (data: Partial<IShop>) => {
  try {
    await axios.put(
      `${server}/shop/update-seller-info`,
      {
        name: data.name,
      },
      { withCredentials: true }
    );
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

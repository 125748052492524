import Lottie from 'react-lottie';
import animationData from '../../../Assests/animations/loading-truck.json';

import { LottieAnimationProps } from '../../../interfaces/common';

const LoadingStateAnimation = ({
                                 width = 250,
                                 height = 100,
                                 ...rest
                               }: LottieAnimationProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
      <div>
        <Lottie options={defaultOptions} width={width} height={height} />
      </div>
  );
};

export default LoadingStateAnimation;

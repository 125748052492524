import axios from 'axios';
import { server } from '../../server';

export const clearProductState = () => (dispatch) => {
  dispatch({
    type: 'clearProductState',
  });
};
// create product
export const createProduct =
  ({
    name,
    description,
    categories,
    originalPrice,
    discountPrice,
    stock,
    images,
    isActive,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'productCreateRequest',
      });
      const payload = {
        description,
        name,
        categories,
        originalPrice,
        stock,
        images,
        isActive,
      };
      console.log('Create product payload:', payload);

      if (discountPrice) {
        payload.discountPrice = discountPrice;
      }

      const { data } = await axios.post(
        `${server}/product/create-product`,
        {
          ...payload,
        },
        {
          withCredentials: true,
        }
      );
      dispatch({
        type: 'productCreateSuccess',
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: 'productCreateFail',
        payload: error?.response?.data?.message || 'Error al crear el producto',
      });
    }
  };

// update product
export const updateProduct =
  (
    _id,
    name,
    description,
    category,
    tags,
    originalPrice,
    discountPrice,
    stock,
    shopId
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'productEditRequest',
      });

      const { data } = await axios.put(
        `${server}/product/update-product`,
        _id,
        name,
        description,
        category,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId
      );
      dispatch({
        type: 'productEditSuccess',
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: 'productEditFail',
        payload: error.response.data.message,
      });
    }
  };

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllProductsShopRequest',
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: 'getAllProductsShopSuccess',
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: 'getAllProductsShopFailed',
      payload: error.response.data.message,
    });
  }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'deleteProductRequest',
    });

    const { data } = await axios.delete(
      `${server}/product/delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: 'deleteProductSuccess',
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: 'deleteProductFailed',
      payload: error.response.data.message,
    });
  }
};

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../components/Layout/AdminHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AdminDashboardMain from '../components/Admin/AdminDashboardMain';
import {useAdminSessionStore} from "../store/adminSession";

const AdminDashboardPage = () => {
  const navigate = useNavigate();

  const { adminSession } = useAdminSessionStore();

  useEffect(() => {
    if (!adminSession) {
      navigate('/admin-login');
    }
  }, [adminSession]);
  return (
    <div>
      <AdminHeader />
      <div className='flex w-full'>
        <div className='flex w-full items-start justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <AdminSideBar active={1} />
          </div>
          <AdminDashboardMain />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardPage;
